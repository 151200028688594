import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyB-oujmuGB9f9yiw0n0kcjiuQqcIC25shk",
    authDomain: "livechat.cwulf.dev",
    projectId: "live-chatroom-59858",
    storageBucket: "live-chatroom-59858.appspot.com",
    messagingSenderId: "783515577182",
    appId: "1:783515577182:web:4fcb4ac5dde561f17c7dcf",
    measurementId: "G-63L7NGEYSY"
};

//init firebase
firebase.initializeApp(firebaseConfig)

//init firestore service
const projectFirestore = firebase.firestore()
const auth = firebase.auth()
const provider = new firebase.auth.GoogleAuthProvider();
const timeStamp = firebase
    .firestore
    .FieldValue
    .serverTimestamp
export { projectFirestore, timeStamp, auth, provider }